<template>
  <div class="parlay-info">
    <ParlayTable>
      <template v-slot:header>
        <span class="parlay-info__header">Ganancia y Probabilidad</span>
      </template>
      <div class="parlay-info__data">
        <div>
          <div class="content money">
            <div>
              Apostando:
            </div>
            <div>
              <InputAmount v-model="amount" :section-name="'parlay'" />
            </div>
          </div>
          <div class="separator"></div>
        </div>
        <div>
          <div class="content momio">
            <div>Momio:</div>
            <div>
              <div v-if="Number.isFinite(totalOdd)"><span v-if="totalOdd > 0">+</span>{{ totalOdd.toFixed(0) }}</div>
              <div>
                <img src="https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="separator"></div>
        </div>
        <div>
          <div class="content to-win">
            <div>Para Ganar:</div>
            <div>
              <div>$ {{ Number.isFinite(payout) ? payout.toFixed(2) : '0.00' }}</div>
              <div>MXN</div>
            </div>
          </div>
          <div class="separator"></div>
        </div>
        <div>
          <div class="content prob">
            <div>Probabilidad:</div>
            <div>
              <span>{{ (totalProbability * 100).toFixed(0) }}</span
              ><span>%</span>
            </div>
          </div>
          <div class="separator last"></div>
        </div>
      </div>
    </ParlayTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ParlaySelectedInfo',
  components: {
    InputAmount: () => import('@/components/Form/InputAmount'),
    ParlayTable: () => import('@/components/Sections/GolTipster-v2/General/ParlayTipsTable'),
  },
  props: {
    parlayBets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      amount: 200,
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    totalProbability() {
      return this.parlayBets.reduce((accumulator, bet) => {
        let currentProbability = accumulator;
        const probActual = bet.prob_actual / 100;
        currentProbability = currentProbability ? currentProbability * probActual : currentProbability + probActual;
        return currentProbability;
      }, 0);
    },
    totalOdd() {
      return this.totalProbability <= 0.5
        ? 100 / this.totalProbability - 100
        : (100 * this.totalProbability) / (this.totalProbability - 1);
    },
    payout() {
      return this.totalOdd > 0
        ? (this.amount * this.totalOdd) / 100 + this.amount
        : (this.amount * 100) / Math.abs(this.totalOdd) + this.amount;
    },
  },
};
</script>

<style scoped lang="scss">
$darkBlue: #132839;
.parlay-info {
  font-size: 1rem;
  width: 100%;

  &__header {
    width: 100%;
    font-family: Roboto-Medium, sans-serif;
    font-size: 1.25em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
  }

  &__data {
    width: 100%;
    max-width: 54em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 0.8em;
    font-family: Roboto-Black, sans-serif;

    & > div {
      width: 100%;
      display: flex;
    }

    & div.content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 0.5em;

      & > div {
        &:first-child {
          font-family: Roboto-Medium, sans-serif;
          font-size: 1.18em;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.16;
          letter-spacing: -0.38px;
          text-align: center;
          color: #000;
        }

        &:last-child {
          color: $darkBlue;
        }
      }
    }

    & div.separator {
      height: 2.5em;
      border-right: 1px dashed #506270;
      margin-top: 0.8em;

      &.last {
        display: none;
      }
    }

    & div.money {
      align-items: flex-start;
      padding-right: 0.4em;

      & > div:last-child {
        margin-top: 0.6em;
      }
    }

    & div.momio {
      & > div {
        &:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.1em;
          flex-wrap: wrap;

          & > div {
            &:first-child {
              font-size: 1.75em;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.79;
              letter-spacing: -0.56px;
              margin-right: 0.5em;
            }
            &:last-child {
              height: 1.8em;
              width: 4em;

              & > img {
                height: 1.8em;
                width: 4em;
              }
            }
          }
        }
      }
    }

    & div.to-win {
      & div {
        &:last-child {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 1em;

          & > div {
            &:first-child {
              font-size: 1.75em;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.79;
              letter-spacing: -0.56px;
              margin-right: 0.4em;
              white-space: nowrap;
            }

            &:last-child {
              font-size: 0.81em;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.08;
              letter-spacing: normal;
            }
          }
        }
      }
    }

    & div.prob {
      align-items: flex-end;

      & > div {
        &:last-child {
          width: 100%;
          max-width: 3em;
          margin-top: 0.25em;
          border-radius: 12px;
          background-color: #cbee6b;
          font-size: 1.75em;
          padding: 0.1em 0.46em;

          & > span {
            &:first-child {
              font-weight: 900;
            }
            &:last-child {
              font-weight: 500;
              margin-left: 0.2em;
            }
          }
        }
      }
    }

    @media screen and (max-width: 780px) {
      grid-template-columns: 1fr 20% 1fr 20%;

      & div.content.momio {
        & > div:last-child {
          margin-top: 0.7em;
        }
      }
    }

    @media screen and (max-width: 690px) {
      grid-template-columns: 1fr;

      & div.content {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: dashed 1px #506270;
        margin-bottom: 0 !important;
        height: 4em;

        & .money {
          & > div:last-child {
            margin-top: 0;
          }
        }

        &.momio {
          & > div:last-child {
            margin-top: 0 !important;
          }
        }

        &.prob {
          border-bottom: unset;
        }

        & > div {
          &:first-child {
            margin-right: 0.5em;

            @media screen and (max-width: 320px) {
              font-size: 1.06em;
            }
          }
          &:last-child {
            margin-top: 0;
          }
        }
      }

      & div.separator {
        display: none;
      }
    }
  }
}
</style>
